import { useNavigate } from 'react-router-dom';

import { Box, Tab } from '@mui/material';
import useOffers from 'api/useOffers';
import { ERollingOfferFormState, OfferType } from 'constants/enums';
import { permissionsUtil } from 'utils/permissionsUtil';
import { HeaderProps } from 'views/RollingOffers/types';

import AcTabs from 'components/AcTabs/AcTabs';
import PageTopBar from 'components/Topbar/PageTopBar';

const RollingOfferHeader: React.FC<HeaderProps> = ({
  edit,
  formikProps,
  rollingOfferId,
  tab,
  setTab,
  setIsUpdateDialogOpen,
  setIsSaveDialogOpen,
  productsSequence,
  isLoading
}) => {
  const { values, submitForm, dirty, isValid } = formikProps;
  const navigate = useNavigate();
  const { getOffer } = useOffers(rollingOfferId, OfferType.ROLLING_OFFER);

  const hasMissingProductDetails = productsSequence.some((sequence) =>
    sequence.products.some((product) => !product.product?.publisherProductId || !product.quantity)
  );

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue:
      | ERollingOfferFormState
      | ((prevState: ERollingOfferFormState) => ERollingOfferFormState)
  ) => {
    setTab(newValue);
  };

  const updateContent = async () => {
    if (productsSequence?.length && submitForm) {
      await submitForm();
    } else {
      if (edit && values.active) {
        setIsUpdateDialogOpen(true);
      } else {
        setIsSaveDialogOpen(true);
      }
    }
  };

  return (
    <>
      <PageTopBar
        withTabsDesign={true}
        disable={false}
        headline={`${edit ? 'Edit' : 'New'} Rolling Offer ${
          edit
            ? `(${
                !getOffer.isLoading && getOffer.data?.result && !isLoading
                  ? getOffer.data?.result?.name
                  : '...'
              })`
            : ''
        }`}
        buttons={[
          {
            text: 'Save',
            action: () => updateContent(),
            disabled:
              isLoading ||
              !isValid ||
              !permissionsUtil.canUserEdit() ||
              hasMissingProductDetails ||
              !values.schedule.timeFrames.length ||
              productsSequence.length < 4 ||
              (edit && (!dirty || getOffer.isLoading))
          }
        ]}
        backFunction={() => navigate('../')}
      />
      <Box pl={'3rem'} pr={'3rem'}>
        <AcTabs value={tab} onChange={handleTabChange}>
          <Tab label="Settings" value={ERollingOfferFormState.GENERAL} />
          <Tab
            label="Configuration"
            value={ERollingOfferFormState.CONFIGURATION}
          />
          <Tab label="Schedule" value={ERollingOfferFormState.SCHEDULE} />
        </AcTabs>
      </Box>
    </>
  );
};

export default RollingOfferHeader;
