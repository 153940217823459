import { useSelector } from 'react-redux';

import { Grid, Stack, Typography } from '@mui/material';
import useImages from 'api/useImages';
import { EAssetType } from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips } from 'hooks/useUpload';
import { AuthSliceState } from 'store/store.types';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import AcSolidInput from 'components/AcSolidInput/AcSolidInput';

import { ThemeFormValues } from '../theme.types';

import 'style/forms.scss';

export const AccountApprovalModalSection: React.FC = () => {
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<ThemeFormValues>();
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const pictures = useImages(currentPublisherId).getImages;

  return (
    <AcCard stackContainer={false} title="Account Approval Modal">
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={6}>
          <AcSelect
            header="Background Image"
            name="general.accountApprovalModal.backgroundImage"
            value={values.general.accountApprovalModal.backgroundImage}
            uploadConfig={{
              onUploadSuccess: async (uploadData: any) => {
                await pictures.refetch();
                setFieldValue(
                  'general.accountApprovalModal.backgroundImage',
                  uploadData!.data.url
                );
              },
              uploadType: EAssetType.BG_POPUP,
              uploadMessage: UploadsTooltips[EAssetType.BG_POPUP]
            }}
            items={[
              {
                content: 'Default Appcharge background',
                key: 'https://media.appcharge.com/defaults/background.png',
                value: 'https://media.appcharge.com/defaults/background.png',
                renderFunction: () => {
                  return (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <img
                        src="https://media.appcharge.com/defaults/background.png"
                        alt="Default Appcharge background"
                        style={{
                          width: 30,
                          maxHeight: 30
                        }}
                      />
                      <Typography>Default Appcharge background</Typography>
                    </Stack>
                  );
                }
              },
              ...pictures.data
                .filter((p: any) => p.type === EAssetType.BG_POPUP)
                .map((picture: any) => {
                  return {
                    content: picture.name,
                    key: picture.url,
                    value: picture.url,
                    renderFunction: () => {
                      return (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <img
                            src={picture.url}
                            alt={picture.name}
                            style={{
                              width: 26,
                              maxHeight: 30
                            }}
                          />
                          <Typography>{picture.name}</Typography>
                        </Stack>
                      );
                    }
                  };
                })
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.general?.accountApprovalModal?.backgroundImage &&
              Boolean(errors.general?.accountApprovalModal?.backgroundImage)
            }
            tooltip="This will be the background of the approval modal."
          />
        </Grid>
        <Grid item xs={6}>
          <AcGradientInput
            header="Background Color"
            name="general.accountApprovalModal.backgroundColor"
            defaultValue={values.general?.accountApprovalModal?.backgroundColor}
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.general?.accountApprovalModal?.backgroundColor &&
              Boolean(errors.general?.accountApprovalModal?.backgroundColor)
            }
            helperText={
              touched.general?.accountApprovalModal?.backgroundColor
                ? errors.general?.accountApprovalModal?.backgroundColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcSolidInput
            header="Border Color"
            name="general.accountApprovalModal.borderColor"
            defaultValue={values.general?.accountApprovalModal?.borderColor}
            onlySolid
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.general?.accountApprovalModal?.borderColor &&
              Boolean(errors.general?.accountApprovalModal?.borderColor)
            }
            helperText={
              touched.general?.accountApprovalModal?.borderColor
                ? errors.general?.accountApprovalModal?.borderColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcInput
            header="Border Width"
            name="general.accountApprovalModal.borderWidth"
            type="number"
            inputProps={{
              max: 6,
              min: 0
            }}
            value={values.general?.accountApprovalModal?.borderWidth}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.general?.accountApprovalModal?.borderWidth &&
              Boolean(errors.general?.accountApprovalModal?.borderWidth)
            }
            helperText={
              touched.general?.accountApprovalModal?.borderWidth
                ? errors.general?.accountApprovalModal?.borderWidth?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcGradientInput
            header="Button Fill Color"
            name="general.accountApprovalModal.buttonBackgroundColor"
            defaultValue={
              values.general?.accountApprovalModal?.buttonBackgroundColor
            }
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.general?.accountApprovalModal?.buttonBackgroundColor &&
              Boolean(
                errors.general?.accountApprovalModal?.buttonBackgroundColor
              )
            }
            helperText={
              touched.general?.accountApprovalModal?.buttonBackgroundColor
                ? errors.general?.accountApprovalModal?.buttonBackgroundColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AcSolidInput
            header="Button Text Color"
            name="general.accountApprovalModal.buttonTextColor"
            defaultValue={values.general?.accountApprovalModal?.buttonTextColor}
            onlySolid
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.general?.accountApprovalModal?.buttonTextColor &&
              Boolean(errors.general?.accountApprovalModal?.buttonTextColor)
            }
            helperText={
              touched.general?.accountApprovalModal?.buttonTextColor
                ? errors.general?.accountApprovalModal?.buttonTextColor?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcSolidInput
            header="Text Color"
            name="general.accountApprovalModal.textColor"
            defaultValue={values.general?.accountApprovalModal?.textColor}
            onlySolid
            setValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.general?.accountApprovalModal?.textColor &&
              Boolean(errors.general?.accountApprovalModal?.textColor)
            }
            helperText={
              touched.general?.accountApprovalModal?.textColor
                ? errors.general?.accountApprovalModal?.textColor?.toString()
                : ''
            }
          />
        </Grid>
      </Grid>
    </AcCard>
  );
};
