import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Background, HugeRollingOffer } from '@appcharge/shared-ui';
import { Grid } from '@mui/material';
import useOffersUI from 'api/useOffersUI';
import useTheme from 'api/useTheme';
import { RollingOfferProductSequence } from 'common/contracts';
import Decimal from 'decimal.js';
import { AuthSliceState } from 'store/store.types';
import { MockProps } from 'views/RollingOffers/types';

const mapProductsSequence = (
  productsSequence: RollingOfferProductSequence[]
) => {
  return productsSequence.map((item) => ({
    ...item,
    prices: {
      priceInUsdCents: new Decimal(item?.priceInUsdCents || 0)
        .mul(100)
        .toNumber(),
      priceDisplay: `$${item?.priceInUsdCents || 0}`,
      currencyCode: 'USD'
    },
    products: item?.products.map((productItem) => ({
      ...productItem,
      ...productItem?.product,
      images: {
        product:
          productItem?.product?.images?.find((img) => img.type === 'product')
            ?.url ||
          productItem?.product?.images?.[0]?.url ||
          null
      }
    }))
  }));
};

const RollingOfferMock: React.FC<MockProps> = ({
  currentOfferUI,
  productsSequence
}) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { getOfferUI } = useOffersUI(currentPublisherId, currentOfferUI);
  const { getStoreTheme } = useTheme(currentPublisherId);

  useEffect(() => {
    if (currentOfferUI) getOfferUI.refetch();
  }, [currentOfferUI, getOfferUI]);

  const isThemeLoading = getStoreTheme?.isLoading;
  const themeData = getStoreTheme?.data;
  const offerUIData = getOfferUI?.data;

  const subRollingOffersProducts = productsSequence
    ? mapProductsSequence(productsSequence)
    : [];

  return (
    <Grid item xs={5} className="iphone-mock-wrapper">
      <div className="iphone-mock" id="iphone-mock">
        <Background
          backgroundImageMobile={
            isThemeLoading ? null : themeData?.general?.backgroundImageMobile
          }
          backgroundImageDesktop={
            isThemeLoading ? null : themeData?.general?.backgroundImageDesktop
          }
          width="100%"
          height="100%"
          position="absolute"
        />
        <HugeRollingOffer
          isPreview={true}
          backgroundImage={offerUIData?.backgroundImage}
          backgroundColor={
            offerUIData?.rollingOffer?.backgroundColor || {
              colorOne: '#323281'
            }
          }
          borderColor={offerUIData?.borderColor}
          borderWidth={offerUIData?.borderWidth}
          indexToCollect={1}
          collectBehavior="disappear"
          arrowColor={offerUIData?.rollingOffer?.arrowColor}
          subRollingOffersProducts={subRollingOffersProducts}
          subRollingOfferUiProps={{
            backgroundColor: offerUIData?.rollingOffer?.subRollingOffer
              ?.backgroundColor || {
              colorOne: '#ffffff'
            },
            backgroundImage:
              offerUIData?.rollingOffer?.subRollingOffer?.backgroundImage,
            lockImage:
              offerUIData?.rollingOffer?.subRollingOffer?.lockImage ||
              'https://media-dev.appcharge.com/media/66be029440fd9d64e8b421a9/1732101369729__f5611ed5-cb85-4b81-b8f1-373921b64d86',
            buttonColor: themeData?.general?.buttonColor,
            collectText:
              offerUIData?.rollingOffer?.subRollingOffer?.collectText,
            buttonTextColor: themeData?.general?.buttonColor
          }}
          selectOffer={async () => true}
        />
      </div>
    </Grid>
  );
};

export default RollingOfferMock;
