import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { TabContext, TabPanel } from '@mui/lab';
import { Grid } from '@mui/material';
import useImages from 'api/useImages';
import useOffers from 'api/useOffers';
import { AxiosError } from 'axios';
import { Offer, RollingOfferProductSequence } from 'common/contracts';
import { TRIMMED_INPUT_REGEX } from 'constants/constants';
import {
  ENotificationType,
  ERollingOfferFormState,
  OfferType
} from 'constants/enums';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { AuthSliceState } from 'store/store.types';
import { errorResponse } from 'utils/errorsTextHelper';
import * as yup from 'yup';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';

import { RollingOfferFormProps } from '../types';

import RollingOfferHeader from './Sections/Header';
import RollingOfferMock from './Sections/Mock';
import RollingOfferModals from './Sections/Modals';
import ConfigurationTab from './Tabs/ConfigurationTab';
import ScheduleTab from './Tabs/ScheduleTab';
import SettingsTab from './Tabs/SettingsTab';

const RollingOfferForm: React.FC<RollingOfferFormProps> = ({
  edit = false,
  dup = false
}) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { rollingOfferId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useNotifications();

  const [data, setData] = useState<Partial<Offer>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [tab, setTab] = useState(ERollingOfferFormState.GENERAL);
  const [currentOfferUI, setCurrentOfferUI] = useState('');
  const [chosenSegment, setChosenSegment] = useState<string[]>([]);

  const { addOffer, updateOffer, getOffer, getOffers } = useOffers(
    rollingOfferId,
    OfferType.ROLLING_OFFER
  );
  const { getImages } = useImages(currentPublisherId);
  const [productsSequence, setProductsSequence] = useState<
    RollingOfferProductSequence[]
  >([]);
  const checkUniqueness = (value: string | undefined, field: keyof Offer) => {
    const isTaken = getOffers.data?.offers.find((o: Offer) => {
      if (!edit) return o[field] === value;
      return o[field] === value && data[field] !== value;
    });
    return !isTaken;
  };

  const offerSchema = yup.object().shape({
    name: yup
      .string()
      .transform((value) => value.trim())
      .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
      .required('Name is required')
      .when('edit', {
        is: false,
        then: yup
          .string()
          .test('uniqueness', 'Name already exists', (value) => {
            return checkUniqueness(value, 'name');
          })
      }),
    offerUiId: yup.string().required('Offer Design is required'),
    publisherOfferId: yup
      .string()
      .transform((value) => value.trim())
      .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
      .required('SKU is required')
      .when('edit', {
        is: false,
        then: yup.string().test('uniqueness', 'SKU already exists', (value) => {
          return checkUniqueness(value, 'publisherOfferId');
        })
      }),
    priceDiscount: yup
      .number()
      .min(0, 'Price discount cannot be negative')
      .max(99, 'Price discount cannot be more than 99%')
      .test(
        'is-one-decimal',
        'Price discount can have one decimal place at most',
        (value) =>
          value === undefined || /^\d+(\.\d{1})?$/.test(value.toString())
      ),
    productSale: yup
      .number()
      .min(0, 'Sale amount cannot be negative')
      .test(
        'is-one-decimal',
        'Sale amount can have one decimal place at most',
        (value) =>
          value === undefined || /^\d+(\.\d{1})?$/.test(value.toString())
      )
  });

  const {
    values,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    dirty,
    errors,
    touched,
    setFieldValue,
    setTouched,
    validateField,
    validateForm
  } = useFormik({
    validateOnMount: true,
    validationSchema: offerSchema,
    enableReinitialize: true,
    initialValues: {
      active: edit ? data?.active : true,
      publisherOfferId: data.publisherOfferId || '',
      name: data.name ? `${data.name}${dup ? '_copy' : ''}` : '',
      type: OfferType.ROLLING_OFFER,
      offerUiId: data.offerUi?._id || data.offerUi?.offerUiId || '',
      segments: data.segments || [],
      schedule: {
        permanent: false,
        timeFrames: data.schedule?.timeFrames || []
      },
      productsSequence:
        (data.productsSequence &&
          data.productsSequence.forEach((product: any) => ({
            ...product,
            priceInUsdCents:
              product?.priceInUsdCents === 0
                ? 0
                : new Decimal(product?.priceInUsdCents).div(100).toNumber()
          }))) ||
        []
    },
    onSubmit: async (values) => {
      const newOffer: Partial<Offer> & { sectionId?: string | null } = {
        active: values.active,
        publisherOfferId: values.publisherOfferId,
        name: values.name,
        type: OfferType.ROLLING_OFFER,
        offerUiId: values.offerUiId,
        segments: chosenSegment,
        schedule: {
          permanent: values.schedule?.permanent || false,
          timeFrames: values.schedule?.timeFrames
        },
        productsSequence: productsSequence.length
          ? productsSequence.map((seq) => {
              return {
                index: seq.index || 1,
                products: seq.products.map((product) => ({
                  productId: product?.product?.productId || 1,
                  quantity: product.quantity,
                  publisherProductId: product.product?.publisherProductId
                })),
                priceInUsdCents:
                  seq?.priceInUsdCents === 0
                    ? 0
                    : new Decimal(seq?.priceInUsdCents).mul(100).toNumber()
              };
            })
          : []
      };
      if (edit && rollingOfferId && !dup) {
        updateOffer.mutate(
          { offerId: rollingOfferId, form: newOffer },
          {
            onSuccess: () => {
              enqueueSnackbar(
                'Rolling Offer edited successfully',
                ENotificationType.SUCCESS
              );
              navigate('../');
            },
            onError: (error) => {
              if (error instanceof AxiosError) {
                enqueueSnackbar(errorResponse(error), ENotificationType.ERROR);
              }
            }
          }
        );
      } else {
        addOffer.mutate(newOffer, {
          onSuccess: () => {
            enqueueSnackbar(
              'New Rolling Offer added successfully',
              ENotificationType.SUCCESS
            );
            navigate('../');
          },
          onError: (error) => {
            if (error instanceof AxiosError) {
              enqueueSnackbar(errorResponse(error), ENotificationType.ERROR);
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    if (!!rollingOfferId && !getOffer.isLoading && getOffer.data?.result) {
      setData(getOffer.data.result);
    }
  }, [rollingOfferId, getOffer?.data, getOffer?.isLoading]);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      setCurrentOfferUI(data.offerUi?._id || '');
      setProductsSequence(
        data.productsSequence?.map((item: any) => ({
          ...item,
          priceInUsdCents:
            item?.priceInUsdCents === 0
              ? 0
              : new Decimal(item?.priceInUsdCents).div(100).toNumber()
        })) || []
      );
    }
  }, [data]);

  useEffect(() => {
    if (!productsSequence?.length) {
      const initialProductsSequence = Array.from({ length: 4 }, (_, i) => ({
        index: i + 1,
        priceInUsdCents: 0,
        products: [
          {
            product: {
              images: []
            }
          }
        ]
      }));
      setProductsSequence(initialProductsSequence);
    }
  }, [productsSequence, setProductsSequence]);

  const formikProps = {
    values,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    dirty,
    errors,
    touched,
    setFieldValue,
    setTouched,
    validateField,
    validateForm
  };

  return (
    <>
      <AcViewWrapper
        header={
          <RollingOfferHeader
            edit={edit}
            formikProps={formikProps}
            rollingOfferId={rollingOfferId}
            tab={tab}
            setTab={setTab}
            setIsUpdateDialogOpen={setIsUpdateDialogOpen}
            setIsSaveDialogOpen={setIsSaveDialogOpen}
            productsSequence={productsSequence}
            setProductsSequence={setProductsSequence}
            isLoading={isLoading}
          />
        }
      >
        {!getImages.isLoading && getImages.data && (
          <AcContentWrapper className="formContent">
            <Grid
              container
              justifyContent={'space-between'}
              position={'relative'}
            >
              <Grid item xs={tab === ERollingOfferFormState.SCHEDULE ? 12 : 6}>
                <TabContext value={tab}>
                  <TabPanel
                    value={ERollingOfferFormState.GENERAL}
                    sx={{ padding: 0 }}
                  >
                    <SettingsTab
                      formikProps={formikProps}
                      rollingOfferId={rollingOfferId}
                      edit={edit}
                      currentOfferUI={currentOfferUI}
                      setCurrentOfferUI={setCurrentOfferUI}
                      chosenSegment={chosenSegment}
                      setChosenSegment={setChosenSegment}
                      data={data}
                      productsSequence={productsSequence}
                      setProductsSequence={setProductsSequence}
                    />
                  </TabPanel>
                  <TabPanel
                    value={ERollingOfferFormState.CONFIGURATION}
                    sx={{ padding: 0 }}
                  >
                    <ConfigurationTab
                      formikProps={formikProps}
                      rollingOfferId={rollingOfferId}
                      productsSequence={productsSequence}
                      setProductsSequence={setProductsSequence}
                    />
                  </TabPanel>
                  <TabPanel
                    value={ERollingOfferFormState.SCHEDULE}
                    sx={{ padding: 0 }}
                  >
                    <ScheduleTab
                      formikProps={formikProps}
                      rollingOfferId={rollingOfferId}
                      edit={edit}
                    />
                  </TabPanel>
                </TabContext>
              </Grid>
              {tab !== ERollingOfferFormState.SCHEDULE && (
                <RollingOfferMock
                  currentOfferUI={currentOfferUI}
                  productsSequence={productsSequence}
                />
              )}
            </Grid>
          </AcContentWrapper>
        )}
      </AcViewWrapper>
      <RollingOfferModals
        isSaveDialogOpen={isSaveDialogOpen}
        isUpdateDialogOpen={isUpdateDialogOpen}
        setIsSaveDialogOpen={setIsSaveDialogOpen}
        setIsUpdateDialogOpen={setIsUpdateDialogOpen}
        submitForm={submitForm}
      />
    </>
  );
};

export default RollingOfferForm;
