import { useEffect, useState } from 'react';

import { EBadgePosition } from '@appcharge/shared-ui';
import { Grid, InputAdornment } from '@mui/material';
import useBadges from 'api/useBadges';
import {
  Badge,
  BundleBadge,
  EBadgeType,
  FormikProps,
  RollingOfferProductSequence
} from 'common/contracts';

import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';

interface CardExtrasCollapseProps {
  formikProps: FormikProps;
  currentPublisherId: string | null;
  productSequenceItem: RollingOfferProductSequence;
}

const CardExtrasCollapse: React.FC<CardExtrasCollapseProps> = ({
  formikProps,
  currentPublisherId,
  productSequenceItem
}) => {
  const { touched, handleBlur, handleChange, errors, setFieldValue } =
    formikProps;
  const [badgeList, setBadgeList] = useState<BundleBadge[]>([]);

  const { getBadges } = useBadges(currentPublisherId);

  useEffect(() => {
    if (!getBadges.data?.result) return;
    const badgesDropdownItems = getBadges.data.result
      .filter((badge: Badge) => badge.type === EBadgeType.EMBLEM)
      .map((badge: Badge) => ({
        content: badge.name,
        name: badge.name,
        key: badge.badgeId,
        value: badge.badgeId,
        url: badge.emblem?.imageUrl,
        position: badge.position as EBadgePosition
      }));
    setBadgeList(badgesDropdownItems);
  }, [getBadges.data]);

  return (
    <Grid container columnSpacing={1.5} rowSpacing={1.5}>
      <Grid item xs={4}>
        <AcInput
          header="Main Product Sale"
          name="productSale"
          value={productSequenceItem?.productSale?.sale}
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            inputProps: {
              min: 0,
              step: 0.1
            }
          }}
          error={touched.productSale && Boolean(errors.productSale)}
          helperText={touched.productSale ? errors.productSale?.toString() : ''}
        />
      </Grid>
      <Grid item xs={4}>
        <AcInput
          header="Price Discount"
          name="priceDiscount"
          value={productSequenceItem?.priceDiscount?.discount}
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            inputProps: {
              min: 0,
              step: 0.1
            }
          }}
          error={touched.priceDiscount && Boolean(errors.priceDiscount)}
          helperText={
            touched.priceDiscount ? errors.priceDiscount?.toString() : ''
          }
        />
      </Grid>
      <Grid item xs={4}>
        <AcSelect
          header="Sale badge (Emblem)"
          name="badge"
          value={productSequenceItem?.badges}
          onChange={handleChange}
          onBlur={handleBlur}
          renderType={EAcSelectItemRenderType.TEXT}
          items={badgeList}
          onClear={() => {
            setFieldValue('badge', null);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CardExtrasCollapse;
