import { ChangeEvent, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import useOffers from 'api/useOffers';
import { useProduct } from 'api/useProduct';
import {
  RollingOfferProductSequence,
  RollingSequenceProduct
} from 'common/contracts';
import { MIN_USD_PRICE } from 'constants/constants';
import { EProductType, OfferType } from 'constants/enums';
import {
  AutocompleteInput,
  ImgOption
} from 'design-system/AutocompleteInput/AutocompleteInput';
import { AuthSliceState } from 'store/store.types';
import { transformQuantityValue } from 'utils/formattingUtil';
import { TabsProps } from 'views/RollingOffers/types';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import DataTable from 'components/DataTable/DataTable';
import { Product } from 'components/ProductsForm/ProductsForm.types';

import CardExtrasCollapse from './CardExtrasCollapse';

import './style.scss';

interface CardProps extends TabsProps {
  productSequenceItem: RollingOfferProductSequence;
  sequenceIndex: number;
  hasMissingProductDetails: any;
}
const SubOfferCard: React.FC<CardProps> = ({
  formikProps,
  rollingOfferId,
  productSequenceItem,
  sequenceIndex,
  productsSequence,
  setProductsSequence,
  hasMissingProductDetails
}) => {
  const { setFieldValue, handleChange } = formikProps;

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { data: productsData, isLoading: isProductsLoading } =
    useProduct(currentPublisherId).getProducts;
  const { formatProductQuantity } = useOffers(
    rollingOfferId,
    OfferType.ROLLING_OFFER
  );

  const isSubOfferFree =
    productSequenceItem?.setAsFree ||
    productSequenceItem?.priceInUsdCents === 0;

  const hasProducts = productSequenceItem?.products?.length > 1;

  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [collapseOpen, setCollapseOpen] = useState(!isSubOfferFree);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentProductType, setCurrentProductType] = useState<EProductType>(
    EProductType.QUANTITY
  );

  const getProductOptions = useMemo(() => {
    if (!productsData?.result) return [];
    return productsData.result.map((product: Product) => ({
      url: product.images?.[0]?.url,
      name: product.name,
      key: product.name,
      value: product.productId,
      type: product.type,
      publisherProductId: product.publisherProductId
    }));
  }, [productsData?.result]);

  const rows = useMemo(() => {
    return (
      productSequenceItem.products?.map((prod, index) => {
        const productDetails = productsData?.result.find(
          (product: Product) => product?.productId === prod?.product?.productId
        );
        return {
          ...prod.product,
          amount: prod.quantity,
          name: productDetails?.name || '',
          productIndex: index,
          sequenceIndex,
          _id: index + sequenceIndex
        };
      }) || []
    );
  }, [productsData, productSequenceItem.products, sequenceIndex]);

  const updateSubOfferData = (
    updatedData: Partial<RollingOfferProductSequence>,
    e?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setProductsSequence((prevSequence: RollingOfferProductSequence[]) => {
      const updatedSequence = [...prevSequence];
      updatedSequence[sequenceIndex] = {
        ...updatedSequence[sequenceIndex],
        ...updatedData
      };
      return updatedSequence;
    });
    setFieldValue(
      'productsSequence',
      (prevSequence: RollingOfferProductSequence[]) => {
        const updatedSequence = [...prevSequence];
        updatedSequence[sequenceIndex] = {
          ...updatedSequence[sequenceIndex],
          ...updatedData
        };
        return updatedSequence;
      }
    );
    handleChange(e);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Product',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <AutocompleteInput
          options={getProductOptions || []}
          onChange={(newValue) => {
            handleProductChange(params.row.productIndex, newValue);
          }}
          currentOption={getProductOptions.find(
            (option: ImgOption) => option.name === params.row.name
          )}
          placeholder="Search"
          autoComplete="off"
        />
      )
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const { productIndex } = params.row;
        return (
          <>
            <AcInput
              name="amount"
              value={productSequenceItem.products[productIndex].quantity || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleQuantityChange(e, productIndex)
              }
              type="number"
              InputProps={{
                inputProps: {
                  min: 1
                }
              }}
            />
            {hasProducts && (
              <DeleteOutlineTwoToneIcon
                onClick={() => handleRemoveProduct(productIndex)}
                className="danger"
                sx={{ cursor: 'pointer', position: 'absolute', right: '10px' }}
              />
            )}
          </>
        );
      }
    }
  ];

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(e.target.value);
    const updatedPrice =
      isNaN(inputValue) || inputValue < MIN_USD_PRICE ? 0 : inputValue;

    const updatedProductSequenceItem = {
      priceInUsdCents: updatedPrice,
      setAsFree: updatedPrice === 0
    };
    updateSubOfferData(updatedProductSequenceItem, e);
  };

  const handleSetAsFreeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const updatedProductSequenceItem = {
      setAsFree: isChecked,
      priceInUsdCents: isChecked
        ? 0
        : Math.max(MIN_USD_PRICE, productSequenceItem?.priceInUsdCents)
    };
    updateSubOfferData(updatedProductSequenceItem, e);
    setCollapseOpen(!isChecked);
  };

  const handleProductChange = (
    productIndex: number,
    newValue: ImgOption | null
  ) => {
    if (!newValue) return;

    const selectedProduct = getProductOptions.find(
      (option: ImgOption) => option.value === newValue.value
    );
    if (selectedProduct) {
      setCurrentProductType(selectedProduct.type);
      const updatedProductSequenceItem = {
        products: productSequenceItem?.products?.map(
          (product: RollingSequenceProduct, index: number) =>
            index === productIndex
              ? {
                  ...product,
                  product: {
                    ...product?.product,
                    name: selectedProduct.name,
                    images: [
                      { url: selectedProduct.url, type: selectedProduct.type }
                    ],
                    publisherProductId: selectedProduct.publisherProductId,
                    productId: selectedProduct.value,
                    type: selectedProduct.type
                  },
                  images: {
                    product: selectedProduct?.url
                  }
                }
              : product
        )
      };
      updateSubOfferData(updatedProductSequenceItem);
    }
  };

  const handleQuantityChange = async (
    e: ChangeEvent<HTMLInputElement>,
    productIndex: number
  ) => {
    try {
      const inputValue = e.target.value;

      const newOffersQuantity =
        inputValue.length !== 0
          ? transformQuantityValue(inputValue)
          : inputValue;

      if (Number(newOffersQuantity) < 1) return;
      const formattedProductQuantity = await formatProductQuantity.mutateAsync({
        quantity: inputValue,
        productType: currentProductType
      });
      const updatedProductSequenceItem = {
        products: productSequenceItem?.products?.map(
          (product: RollingSequenceProduct, index: number) =>
            index === productIndex
              ? {
                  ...product,
                  quantity: newOffersQuantity,
                  quantityDisplay: formattedProductQuantity.data
                }
              : product
        )
      };
      updateSubOfferData(updatedProductSequenceItem, e);
    } catch (error) {
      console.error('Error in handleQuantityChange:', error);
    }
  };

  const handleRemoveProduct = (productIndex: number) => {
    if (!hasProducts) return;
    const updatedProductSequenceItem = {
      products: productSequenceItem?.products?.filter(
        (_: RollingSequenceProduct, index: number) => index !== productIndex
      )
    };
    updateSubOfferData(updatedProductSequenceItem);
  };

  const handleAddProduct = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newProductIndex = productSequenceItem.products.length;
    const updatedProductSequenceItem = {
      products: [
        ...productSequenceItem.products,
        {
          product: {
            images: []
          }
        }
      ]
    };
    updateSubOfferData(updatedProductSequenceItem, e);

    setSelectionModel([newProductIndex]);
  };

  const handleDeleteProductSequence = async (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    setProductsSequence((prevSequence: RollingOfferProductSequence[]) =>
      prevSequence
        .filter(
          (_: RollingOfferProductSequence, index: number) =>
            index !== sequenceIndex
        )
        .map((item: RollingOfferProductSequence, index: number) => ({
          ...item,
          index: index + 1
        }))
    );
    handleChange(e);
    setMenuAnchor(null);
  };

  const handleDuplicateProductSequence = async (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    setProductsSequence((prevSequence: RollingOfferProductSequence[]) => {
      const duplicatedSequence = {
        ...prevSequence[sequenceIndex],
        index: prevSequence.length + 1
      };

      return [
        ...prevSequence.slice(0, sequenceIndex + 1),
        duplicatedSequence,
        ...prevSequence.slice(sequenceIndex + 1)
      ].map((item: RollingOfferProductSequence, index: number) => ({
        ...item,
        index: index + 1
      }));
    });
    handleChange(e);
    setMenuAnchor(null);
  };

  return (
    <AcCard stackContainer={false}>
      <Grid container className="sub-index-card">
        <Grid item xs={10} mb={2}>
          <Typography fontFamily="'Montserrat', sans-serif" fontWeight={500}>
            {`Offer ${productSequenceItem.index}`}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setMenuAnchor(event.currentTarget)
            }
            sx={{ padding: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={!!menuAnchor}
            onClose={() => setMenuAnchor(null)}
          >
            <MenuItem
              key={'duplicate'}
              onClick={handleDuplicateProductSequence}
              disabled={hasMissingProductDetails}
            >
              <div className="card-actions">
                <ContentCopyOutlinedIcon /> Duplicate
              </div>
            </MenuItem>
            <MenuItem
              key={'delete'}
              onClick={handleDeleteProductSequence}
              disabled={productsSequence.length <= 4}
            >
              <div className="card-actions danger">
                <DeleteOutlineTwoToneIcon /> Delete
              </div>
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={6}>
          <AcInput
            header="Price"
            name={`price-${sequenceIndex}`}
            value={
              isSubOfferFree ? 'Free' : productSequenceItem?.priceInUsdCents
            }
            disabled={isSubOfferFree}
            onChange={handlePriceChange}
            type={isSubOfferFree ? 'text' : 'number'}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {isSubOfferFree ? '' : '$'}
                </InputAdornment>
              ),
              inputProps: { min: MIN_USD_PRICE }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Set as Free"
            style={{ marginTop: '12px', marginLeft: '-2px' }}
            control={
              <Checkbox
                name={`setAsFree-${sequenceIndex}`}
                style={{ padding: '0' }}
                checked={isSubOfferFree}
                onChange={handleSetAsFreeChange}
              />
            }
            componentsProps={{
              typography: {
                fontSize: 14,
                sx: { marginLeft: '2px' },
                color: '#717188'
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapseOpen}>
            <Divider sx={{ width: '100%' }} />
            <CardExtrasCollapse
              formikProps={formikProps}
              currentPublisherId={currentPublisherId}
              productSequenceItem={productSequenceItem}
            />
          </Collapse>
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid item xs={12}>
          <DataTable
            allowEmptyRows
            columns={columns}
            rows={rows}
            loading={isProductsLoading}
            className="custom-data-grid"
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleAddProduct}
            className="add-product-button"
            disabled={productSequenceItem?.products?.length >= 3}
          >
            + Add product
          </Button>
        </Grid>
      </Grid>
    </AcCard>
  );
};

export default SubOfferCard;
