import { ELocalStorageKeys } from '../constants/enums';
import { localStorageUtil } from '../utils/localStorageUtil';

const MOCK_URL = 'https://api-dev.appcharge.com/mock/mock';

export const fetchConfig = {
  general: {
    refetchOnWindowFocus: false
  },
  list: {
    refetchOnWindowFocus: false
  },
  single: {
    refetchOnWindowFocus: false,
    enabled: false
  }
};

export enum EApiRoutes {
  COUPONS = 'COUPONS',
  COUPONS_CONFIGURATION = 'COUPONS_CONFIGURATION',
  PROMO_CODE = 'PROMO_CODE',
  USERS = 'USERS',
  ADD_USER = 'ADD_USER',
  UPDATE_USER_ROLE = 'UPDATE_USER_ROLE',
  DELETE_USER = 'DELETE_USER',
  RESET_USER = 'RESET_USER',
  UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD',
  FORGOT_USER_PASSWORD = 'FORGOT_USER_PASSWORD',
  LOGIN_USER = 'LOGIN_USER',
  LOGIN_USER_GOOGLE = 'LOGIN_USER_GOOGLE',
  LOGOUT_USER = 'LOGOUT_USER',
  RESEND_USER_INVITATION = 'RESEND_USER_INVITATION',
  BUNDLES = 'BUNDLES',
  DUPLICATE_BUNDLE = 'DUPLICATE_BUNDLE',
  DELETE_IMAGES = 'DELETE_IMAGES',
  IMAGES = 'IMAGES',
  INTEGRATION = 'INTEGRATION',
  ORDERS = 'ORDERS',
  ORDER_DETAILS = 'ORDER_DETAILS',
  PLAYERS = 'PLAYERS',
  PLAYER = 'PLAYER',
  BLOCKED_PLAYERS = 'BLOCKED_PLAYERS',
  DELETE_PLAYERS = 'DELETE_PLAYERS',
  ADD_PLAYERS_LIST = 'ADD_PLAYERS_LIST',
  POST_IMAGE = 'POST_IMAGE',
  PRODUCT_QUANTITY_FORMAT = 'PRODUCT_QUANTITY_FORMAT',
  PRODUCTS = 'PRODUCTS',
  PRICING = 'PRICING',
  PRICING_META = 'PRICING_META',
  PRICE_POINT = 'PRICE_POINT',
  PUBLISHER = 'PUBLISHER',
  PUBLISHER_SETTINGS = 'PUBLISHER_SETTINGS',
  PUBLISHER_USERS = 'PUBLISHER_USERS',
  SEGMENTS = 'SEGMENTS',
  PUBLISHER_TAGS = 'PUBLISHER_TAGS',
  GRAPHS = 'GRAPHS',
  BARS = 'BARS',
  PIES = 'PIES',
  STATS = 'STATS',
  THEME = 'THEME',
  CHECKOUT_THEME = 'CHECKOUT_THEME',
  GEO = 'GEO',
  REFUND = 'REFUND',
  PAYMENTS = 'PAYMENTS',
  PAYMENTS_CONFIG = 'PAYMENTS_CONFIG',
  OFFERS_UI = 'OFFERS_UI',
  BADGES = 'BADGES',
  GET_BADGES = 'GET_BADGES',
  OFFERS = 'OFFERS',
  POPUP_OFFERS = 'POPUP_OFFERS',
  FINANCIAL_REPORTS = 'FINANCIAL_REPORTS',
  DISPUTES = 'DISPUTES',
  SECTIONS = 'SECTIONS',
  UPSERT_SECTIONS = 'UPSERT_SECTIONS',
  PAYOUTS = 'PAYOUTS',
  FEATURE_FLAGS = 'FEATURE_FLAGS',
  PRICING_POINTS_TEMPLATE = 'PRICING_POINTS_TEMPLATE',
  ROLES = 'ROLES',
  LOCALIZATION_TRANSLATIONS = 'LOCALIZATION_TRANSLATIONS',
  GENERATE_DEEPLINK = 'GENERATE_DEEPLINK'
}

export const ApiRoutes = {
  COUPONS: '/dashboard/coupons',
  COUPONS_CONFIGURATION: '/dashboard/coupons/configuration',
  PROMO_CODE: '/dashboard/coupons/promoCode',
  USERS: '/dashboard/v2/user/me',
  ADD_USER: '/dashboard/v2/user/invite',
  UPDATE_USER_ROLE: '/dashboard/v2/user',
  DELETE_USER: '/dashboard/v2/user',
  RESET_USER: '/dashboard/user/password/reset',
  UPDATE_USER_PASSWORD: '/dashboard/user/password/update',
  FORGOT_USER_PASSWORD: '/dashboard/user/password/forgot',
  LOGIN_USER: '/dashboard/user/login',
  LOGIN_USER_GOOGLE: '/dashboard/user/login/google',
  LOGOUT_USER: '/dashboard/user/logout',
  RESEND_USER_INVITATION: '/dashboard/v2/user/reinvite',
  BUNDLES: '/api/offersets',
  DUPLICATE_BUNDLE: '/api/offersets/duplicate',
  DELETE_IMAGES: '/dashboard/media',
  IMAGES: '/dashboard/media',
  INTEGRATION: '/dashboard/integration',
  ORDERS: '/dashboard/v2/order',
  ORDER_DETAILS: '/dashboard/order',
  PLAYER: '/api/tags',
  PLAYERS: '/api/tags?full_details=true',
  BLOCKED_PLAYERS: '/dashboard/blocked-players',
  DELETE_PLAYERS: '/api/tags/list/deleteBatch',
  ADD_PLAYERS_LIST: '/api/tags/batch',
  POST_IMAGE: '/dashboard/media/upload/',
  PRODUCTS: '/dashboard/product',
  PRICING: '/dashboard/pricing',
  PRICING_META: '/dashboard/pricing/pricing-meta',
  PRICE_POINT: '/dashboard/pricing/pricePoint',
  PUBLISHER: '/dashboard/publisher',
  PUBLISHER_SETTINGS: '/dashboard/publisher/publisher-settings',
  PUBLISHER_USERS: '/dashboard/v2/users',
  SEGMENTS: '/dashboard/segment',
  PUBLISHER_TAGS: '/dashboard/offer-tags',
  GRAPHS: '/analytics/graphs',
  BARS: '/analytics/bars',
  PIES: '/analytics/pies',
  STATS: '/analytics/stats',
  THEME: '/dashboard/storeTheme',
  CHECKOUT_THEME: '/dashboard/checkoutTheme',
  GEO: '/dashboard/financial/geo',
  REFUND: '/dashboard/order/refund',
  PAYMENTS: '/dashboard/financial/payment-methods',
  PAYMENTS_CONFIG: '/dashboard/financial/configuration',
  OFFERS_UI: '/dashboard/offerUI',
  BADGES: '/dashboard/badge',
  GET_BADGES: '/dashboard/badges',
  OFFERS: '/dashboard/offer',
  POPUP_OFFERS: '/dashboard/offer/popup/daily-bonus',
  FINANCIAL_REPORTS: '/dashboard/v2/report',
  PRODUCT_QUANTITY_FORMAT: '/dashboard/offer/format-product-quantity',
  DISPUTES: '/dashboard/disputes',
  SECTIONS: '/dashboard/sections',
  UPSERT_SECTIONS: '/dashboard/sections/upsert',
  PAYOUTS: '/dashboard/v2/payouts',
  ROLES: '/dashboard/v2/user/roles',
  FEATURE_FLAGS: 'dashboard/dashboard-settings',
  PRICING_POINTS_TEMPLATE: '/dashboard/pricing/download',
  LOCALIZATION_TRANSLATIONS: '/dashboard/translations',
  GENERATE_DEEPLINK: '/dashboard/offer'
};

export const getApiRouteOrMock = (route: EApiRoutes): string => {
  if (process.env.NODE_ENV === 'production') {
    return ApiRoutes[route];
    // return
  } else {
    const mockApis = localStorageUtil.get<Partial<typeof ApiRoutes>>(
      ELocalStorageKeys.MOCK_API
    );
    if (mockApis && mockApis[route]) {
      return `${MOCK_URL}${ApiRoutes[route]}` as string;
    }
    return ApiRoutes[route];
  }
};
