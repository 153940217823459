import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Divider, Grid, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { systemTexts } from 'constants/localization';
import { useFormik } from 'formik';
import { AuthSliceState } from 'store/store.types';

import usePublisher from '../../../api/usePublisher';
import useTheme from '../../../api/useTheme';
import { ELogoSize, ENotificationType } from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import ActionButton from '../../ActionButton/ActionButton';
import { defaultTheme } from '../../Theme/Theme';

import { QuantityFormat } from './Components/QuantityFormat';
import { storeLayoutValidationSchema } from './validation-schema';

import '../../../style/forms.scss';
import '../../Theme/style.scss';

const StoreLayout: React.FC = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const themeActions = useTheme(currentPublisherId);
  const { data, isFetching } = themeActions.getStoreTheme;
  const publisherActions = usePublisher(currentPublisherId || undefined);
  const publisher = publisherActions.getPublisher;

  const { enqueueSnackbar } = useNotifications();

  const initialValues = useMemo(
    () => ({
      general: {
        logo: data?.general?.logo || defaultTheme.general.logo,
        logoSize: data?.general?.logoSize || ELogoSize.SMALL,
        font: data?.general?.font || defaultTheme.general.font,
        tabName: publisher.data?.storeTabName || defaultTheme.general.tabName,
        favicon: data?.general?.favicon || defaultTheme.general.favicon,
        buttonColor: {
          colorOne:
            data?.general?.buttonColor.colorOne ||
            defaultTheme.general.buttonColor.colorOne,
          colorTwo:
            data?.general?.buttonColor.colorTwo ||
            defaultTheme.general.buttonColor.colorTwo,
          gradientDirection:
            data?.general?.buttonColor.gradientDirection ||
            defaultTheme.general.buttonColor.gradientDirection
        },
        backgroundImageMobile:
          data?.general?.backgroundImageMobile ||
          defaultTheme.general.backgroundImageMobile,
        backgroundImageDesktop:
          data?.general?.backgroundImageDesktop ||
          defaultTheme.general.backgroundImageDesktop,
        buttonTextColor:
          data?.general?.buttonTextColor ||
          defaultTheme.general.buttonTextColor,
        bundlesViewModel:
          data?.general?.bundlesViewModel ||
          defaultTheme.general.bundlesViewModel,
        bundlesInternalViewModel:
          data?.general?.bundlesInternalViewModel ||
          defaultTheme.general.bundlesInternalViewModel,
        specialOffersViewModel:
          data?.general?.specialOffersViewModel ||
          defaultTheme.general.specialOffersViewModel,
        specialOffersInternalViewModel:
          data?.general?.specialOffersInternalViewModel ||
          defaultTheme.general.specialOffersInternalViewModel,
        balancesTheme:
          data?.general?.balancesTheme || defaultTheme.general.balancesTheme,
        sectionViewModel:
          data?.general?.sectionViewModel ||
          defaultTheme.general.sectionViewModel,
        popupRatio:
          data?.general?.popupRatio || defaultTheme.general.popupRatio,
        productsQuantityFormat: {
          milSeparator:
            data?.general?.productsQuantityFormat?.milSeparator ||
            defaultTheme.general?.productQuantityFormat?.milSeparator,
          fractionalSeparator:
            data?.general?.productsQuantityFormat.fractionalSeparator ||
            defaultTheme.general?.productQuantityFormat?.fractionalSeparator,
          thousandShorthand:
            data?.general?.productsQuantityFormat?.thousandShorthand ||
            defaultTheme.general.productQuantityFormat?.thousandShorthand,
          millionShorthand:
            data?.general?.productsQuantityFormat?.millionShorthand ||
            defaultTheme.general.productQuantityFormat?.millionShorthand,
          billionShorthand:
            data?.general?.productsQuantityFormat?.billionShorthand ||
            defaultTheme.general?.productQuantityFormat?.billionShorthand
        },
        specialOfferQuantityFormat: {
          milSeparator:
            data?.general?.specialOfferQuantityFormat?.milSeparator ||
            defaultTheme.general?.specialOfferQuantityFormat?.milSeparator,
          fractionalSeparator:
            data?.general?.specialOfferQuantityFormat?.fractionalSeparator ||
            defaultTheme.general?.specialOfferQuantityFormat
              ?.fractionalSeparator,
          thousandShorthand:
            data?.general?.specialOfferQuantityFormat?.thousandShorthand ||
            defaultTheme.general.specialOfferQuantityFormat?.thousandShorthand,
          millionShorthand:
            data?.general?.specialOfferQuantityFormat?.millionShorthand ||
            defaultTheme.general.specialOfferQuantityFormat?.millionShorthand,
          billionShorthand:
            data?.general?.specialOfferQuantityFormat?.billionShorthand ||
            defaultTheme.general.specialOfferQuantityFormat?.billionShorthand
        },
        balanceQuantityFormat: {
          milSeparator:
            data?.general?.balanceQuantityFormat?.milSeparator ||
            defaultTheme.general.balanceQuantityFormat?.milSeparator,
          fractionalSeparator:
            data?.general?.balanceQuantityFormat?.fractionalSeparator ||
            defaultTheme.general.balanceQuantityFormat?.fractionalSeparator,
          thousandShorthand:
            data?.general?.balanceQuantityFormat?.thousandShorthand ||
            defaultTheme.general.balanceQuantityFormat?.thousandShorthand,
          millionShorthand:
            data?.general?.balanceQuantityFormat?.millionShorthand ||
            defaultTheme.general.balanceQuantityFormat.millionShorthand,
          billionShorthand:
            data?.general?.balanceQuantityFormat?.billionShorthand ||
            defaultTheme.general.balanceQuantityFormat?.billionShorthand
        },
        accountApprovalModal: {
          backgroundImage:
            data?.general?.accountApprovalModal?.backgroundImage ||
            defaultTheme.general.accountApprovalModal.backgroundImage,
          backgroundColor: {
            colorOne:
              data?.general?.accountApprovalModal?.backgroundColor?.colorOne ||
              defaultTheme.general.accountApprovalModal.backgroundColor
                .colorOne,
            colorTwo:
              data?.general?.accountApprovalModal?.backgroundColor?.colorTwo ||
              defaultTheme.general.accountApprovalModal.backgroundColor
                .colorTwo,
            gradientDirection:
              data?.general?.accountApprovalModal?.backgroundColor
                ?.gradientDirection ||
              defaultTheme.general.accountApprovalModal.backgroundColor
                .gradientDirection
          },
          borderColor:
            data?.general?.accountApprovalModal?.borderColor ||
            defaultTheme.general.accountApprovalModal.borderColor,
          borderWidth:
            data?.general?.accountApprovalModal?.borderWidth ||
            defaultTheme.general.accountApprovalModal.borderWidth,
          buttonBackgroundColor: {
            colorOne:
              data?.general?.accountApprovalModal?.buttonBackgroundColor
                ?.colorOne ||
              defaultTheme.general.accountApprovalModal.buttonBackgroundColor
                .colorOne,
            colorTwo:
              data?.general?.accountApprovalModal?.buttonBackgroundColor
                ?.colorTwo ||
              defaultTheme.general.accountApprovalModal.buttonBackgroundColor
                .colorTwo,
            gradientDirection:
              data?.general?.accountApprovalModal?.buttonBackgroundColor
                ?.gradientDirection ||
              defaultTheme.general.accountApprovalModal.buttonBackgroundColor
                .gradientDirection
          },
          buttonTextColor:
            data?.general?.accountApprovalModal?.buttonTextColor ||
            defaultTheme.general.accountApprovalModal.buttonTextColor,
          textColor:
            data?.general?.accountApprovalModal?.textColor ||
            defaultTheme.general.accountApprovalModal.textColor
        }
      },
      login: {
        text: data?.login?.text || defaultTheme.login.text,
        font: data?.login?.font || defaultTheme.login.font,
        textSize: data?.login?.textSize || defaultTheme.login.textSize,
        textColor: data?.login?.textColor || defaultTheme.login.textColor,
        textWeight: data?.login?.textWeight || defaultTheme.login.textWeight,
        otpButton: {
          backgroundColor: {
            colorOne:
              data?.login?.otpButton?.backgroundColor?.colorOne ||
              defaultTheme.login.otpButton.backgroundColor.colorOne,
            colorTwo:
              data?.login?.otpButton?.backgroundColor?.colorTwo ||
              defaultTheme.login.otpButton.backgroundColor.colorTwo,
            gradientDirection:
              data?.login?.otpButton?.backgroundColor?.gradientDirection ||
              defaultTheme.login.otpButton.backgroundColor.gradientDirection
          },
          icon:
            data?.login?.otpButton?.icon || defaultTheme.login.otpButton.icon,
          text:
            data?.login?.otpButton?.text || defaultTheme.login.otpButton.text,
          textColor: {
            colorOne:
              data?.login?.otpButton?.textColor?.colorOne ||
              defaultTheme.login.otpButton.textColor.colorOne,
            colorTwo:
              data?.login?.otpButton?.textColor?.colorTwo ||
              defaultTheme.login.otpButton.textColor.colorTwo,
            gradientDirection:
              data?.login?.otpButton?.textColor?.gradientDirection ||
              defaultTheme.login.otpButton.textColor.gradientDirection
          }
        },
        consentSection: {
          htmlText:
            data?.login?.consentSection?.htmlText ||
            defaultTheme.login.consentSection.htmlText,
          consentRequired:
            data?.login?.consentSection?.consentRequired ||
            defaultTheme.login.consentSection.consentRequired
        }
      },
      loaderScreen: {
        headerText:
          data?.loaderScreen?.headerText ||
          defaultTheme.loaderScreen.headerText,
        headerColor: {
          colorOne:
            data?.loaderScreen?.headerColor.colorOne ||
            defaultTheme.loaderScreen.headerColor.colorOne,
          colorTwo:
            data?.loaderScreen?.headerColor.colorTwo ||
            defaultTheme.loaderScreen.headerColor.colorTwo,
          gradientDirection:
            data?.loaderScreen?.headerColor.gradientDirection ||
            defaultTheme.loaderScreen.headerColor.gradientDirection
        },
        headerSize:
          data?.loaderScreen?.headerSize ||
          defaultTheme.loaderScreen.headerSize,
        headerWeight:
          data?.loaderScreen?.headerWeight ||
          defaultTheme.loaderScreen.headerWeight,
        text: data?.loaderScreen?.text || defaultTheme.loaderScreen.text,
        textColor:
          data?.loaderScreen?.textColor || defaultTheme.loaderScreen.textColor,
        textSize:
          data?.loaderScreen?.textSize || defaultTheme.loaderScreen.textSize,
        textWeight:
          data?.loaderScreen?.textWeight || defaultTheme.loaderScreen.textWeight
      },
      completedScreen: {
        headerText:
          data?.completedScreen?.headerText ||
          defaultTheme.completedScreen.headerText,
        headerColor: {
          colorOne:
            data?.completedScreen?.headerColor.colorOne ||
            defaultTheme.completedScreen.headerColor.colorOne,
          colorTwo:
            data?.completedScreen?.headerColor.colorTwo ||
            defaultTheme.completedScreen.headerColor.colorTwo,
          gradientDirection:
            data?.completedScreen?.headerColor.gradientDirection ||
            defaultTheme.completedScreen.headerColor.gradientDirection
        },
        headerSize:
          data?.completedScreen?.headerSize ||
          defaultTheme.completedScreen.headerSize,
        headerWeight:
          data?.completedScreen?.headerWeight ||
          defaultTheme.completedScreen.headerWeight,
        text: data?.completedScreen?.text || defaultTheme.completedScreen.text,
        textColor:
          data?.completedScreen?.textColor ||
          defaultTheme.completedScreen.textColor,
        textSize:
          data?.completedScreen?.textSize ||
          defaultTheme.completedScreen.textSize,
        textWeight:
          data?.completedScreen?.textWeight ||
          defaultTheme.completedScreen.textWeight,
        backToGameButtonText:
          data?.completedScreen?.backToGameButtonText ||
          defaultTheme.completedScreen.backToGameButtonText
      },
      storeScreen: {
        addToHomeScreen: {
          active:
            data?.storeScreen?.addToHomeScreen?.active ||
            defaultTheme.storeScreen.addToHomeScreen.active,
          buttonImage:
            data?.storeScreen?.addToHomeScreen?.buttonImage ||
            defaultTheme.storeScreen.addToHomeScreen.buttonImage,
          iconImage:
            data?.storeScreen?.addToHomeScreen?.iconImage ||
            defaultTheme.storeScreen.addToHomeScreen.iconImage,
          shortcutName:
            data?.storeScreen?.addToHomeScreen?.shortcutName ||
            defaultTheme.storeScreen.addToHomeScreen.shortcutName,
          borderColor: {
            colorOne:
              data?.storeScreen?.addToHomeScreen?.borderColor?.colorOne ||
              defaultTheme.storeScreen.addToHomeScreen.borderColor.colorOne
          },
          backgroundColor: {
            colorOne:
              data?.storeScreen?.addToHomeScreen?.backgroundColor?.colorOne ||
              defaultTheme.storeScreen.addToHomeScreen.backgroundColor.colorOne,
            colorTwo:
              data?.storeScreen?.addToHomeScreen?.backgroundColor?.colorTwo ||
              defaultTheme.storeScreen.addToHomeScreen.backgroundColor.colorTwo
          }
        },
        bundleBorderColor: {
          colorOne:
            data?.storeScreen?.bundleBorderColor.colorOne ||
            defaultTheme.storeScreen.bundleBorderColor.colorOne,
          colorTwo:
            data?.storeScreen?.bundleBorderColor.colorTwo ||
            defaultTheme.storeScreen.bundleBorderColor.colorTwo,
          gradientDirection:
            data?.storeScreen?.bundleBorderColor.gradientDirection ||
            defaultTheme.storeScreen.bundleBorderColor.gradientDirection
        },
        noOffersTitleText:
          data?.storeScreen.noOffersTitleText || defaultTheme.storeScreen,
        noOffersMessageText: data?.storeScreen.noOffersMessageText
      },
      storeTemplate: {
        bodyBlocksOrder:
          data?.storeTemplate?.bodyBlocksOrder ||
          defaultTheme.storeTemplate.bodyBlocksOrder
      }
    }),
    [data, publisher]
  );

  const handleSubmit = async (values: any) => {
    try {
      await themeActions.updateStoreTheme.mutateAsync(values);
      enqueueSnackbar(
        systemTexts.settingsTexts.THEME_UPDATED,
        ENotificationType.SUCCESS
      );
      setSubmitting(false);
    } catch (error) {
      enqueueSnackbar(
        systemTexts.settingsTexts.THEME_UPDATE_ERROR,
        ENotificationType.ERROR,
        (
          (error as AxiosError).response?.data as {
            message: string;
          }
        ).message || 'Unknown error occurred'
      );
    }
  };

  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setSubmitting
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: storeLayoutValidationSchema,
    onSubmit: handleSubmit
  });

  return (
    <Grid container className="formContent">
      <Grid item>
        <Stack>
          <QuantityFormat
            title={systemTexts.settingsTexts.TITLES.PRODUCT_QUANTITY_FORMAT}
            quantityFormat={values.general.productsQuantityFormat}
            name={'general.productsQuantityFormat'}
            isFetching={isFetching}
            touched={touched.general?.productsQuantityFormat}
            errors={errors.general?.productsQuantityFormat}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <Divider />
          <QuantityFormat
            title={systemTexts.settingsTexts.TITLES.BALANCE_QUANTITY_FORMAT}
            quantityFormat={values.general.balanceQuantityFormat}
            name={'general.balanceQuantityFormat'}
            isFetching={isFetching}
            touched={touched.general?.balanceQuantityFormat}
            errors={errors.general?.balanceQuantityFormat}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <Divider />
          <QuantityFormat
            title={
              systemTexts.settingsTexts.TITLES.SPECIAL_OFFER_QUANTITY_FORMAT
            }
            quantityFormat={values.general.specialOfferQuantityFormat}
            name={'general.specialOfferQuantityFormat'}
            isFetching={isFetching}
            touched={touched.general?.specialOfferQuantityFormat}
            errors={errors.general?.specialOfferQuantityFormat}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </Stack>
        <Grid item xs={6} mt={3}>
          <ActionButton
            variant="outlined"
            text={systemTexts.actionTexts.UPDATE}
            onClick={submitForm}
            disabled={!isValid || !dirty || isSubmitting}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={7} className="iphone-mock-wrapper">
                <div
                    className="store-preview-wrapper iphone-mock"
                    style={{ overflow: 'hidden' }}
                >
                    <Background
                        backgroundImageMobile={
                            values.general.backgroundImageMobile
                        }
                        backgroundImageDesktop={
                            values.general.backgroundImageMobile
                        }
                        width="100%"
                        height="100%"
                        position="absolute"
                    />
                    <img
                        style={{ position: 'relative' }}
                        alt="logo"
                        className={`storeLogo ${values.general.logoSize}`}
                        src={values.general.logo}
                    />
                    <div className="bundles-wrapper">
                        <SectionLayout sectionName={''} />
                        <BundlesLayout mobileView={bundlesLayoutMobile}>
                            {Array.from(
                                {
                                    length:
                                        values.general.bundlesViewModel ===
                                        EBundlesViewModel.THREE_IN_ONE
                                            ? 3
                                            : 1
                                },
                                () => null
                            ).map((i, index) => (
                                <div className="bundle" key={index}>
                                    <BundlesInternalLayout
                                        bundleBorderColor={
                                            values.storeScreen.bundleBorderColor
                                        }
                                        productList={[]}
                                        bundleButtonColor={
                                            values.general.buttonColor
                                        }
                                        backgroundSrc="https://media.appcharge.com/defaults/background.png"
                                        bundleButtonTextColor={
                                            values.general.buttonTextColor
                                        }
                                        fontFamily={values.general.font}
                                        price={{
                                            price: 0,
                                            isCents: true,
                                            fractionalSeparator: '.',
                                            milSeparator: ',',
                                            spacing: false,
                                            symbolPosition: 'left',
                                            currencySymbol: '$',
                                            currencyCode: 'USD'
                                        }}
                                        backgroundColor={{
                                            colorOne: 'transparent'
                                        }}
                                        inMobileMock={true}
                                    />
                                </div>
                            ))}
                        </BundlesLayout>
                    </div>
                </div>
            </Grid> */}
    </Grid>
  );
};

export default StoreLayout;
