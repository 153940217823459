import { EEncryptionType } from './IntegrationSettings/IntegrationSettings';

export enum EPlayersAuthenticationModel {
  PUBLISHER = 'publisher',
  APPCHARGE = 'appcharge'
}

export enum EPublisherEventValue {
  ORDER_CREATED = "order_created",
  PAYMENT_INTENT_SUCCESS = "payment_intent_success",
  PAYMENT_INTENT_FAILED = "payment_intent_failed",
  ORDER_COMPLETED_SUCCESS = "order_completed_success",
  ORDER_COMPLETED_FAILED = "order_completed_failed",
  ORDER_CANCELED = "order_canceled",
  ORDER_REFUNDED = "order_refunded",
  ORDER_DISPUTE_OPEN = "order_dispute_open",
  ORDER_DISPUTE_LOST = "order_dispute_lost",
  ORDER_DISPUTE_WON = "order_dispute_won",
  OFFERS_SHOWN = "offers_shown",
  OFFERS_LOADED = "offers_loaded",
  LOGIN_LAND = "login_land",
  LOGIN_CLICKED = "login_clicked",
  LOGIN_SCREEN_PRESENTED = "login_screen_presented",
  LOGIN_OTP_IOS_BUTTON = "login_otp_ios_button",
  LOGIN_OTP_ANDROID_BUTTON = "login_otp_android_button",
  LOGIN_CANCELED = "login_canceled",
  LOGIN_APPROVAL = "login_approval",
  LOGIN_RESULT = "login_result",
  INVALID_PROPERTY_ERROR = "invalid_property_error"
}

export interface IntegrationProfile {
  ordersReportingApiUrl: string;
  playerInfoSyncUrl: string;
  playersAuthWebhook: string;
  eventsWebhookUrl: string;
  fbAppId: string;
}

export interface IntegrationSettingsData {
  _id: string;
  apiUrlPrimaryKey: string;
  apiUrlSecondaryKey: string;
  checkoutPublicKey: string;
  backToGameDeepLink: string;
  ordersReportingApiUrl: string;
  playersAuthModel: string;
  publisherToken: string;
  playerInfoSyncUrl: string;
  playersAuthWebhook: string;
  publisherId: string;
  usernamePasswordOn: boolean;
  facebookModel: EPlayersAuthenticationModel;
  usernamePasswordModel: EPlayersAuthenticationModel;
  deepLinkButtonOn: boolean;
  backToStoreButtonOn: boolean;
  encryptionModel: EEncryptionType;
  eventsWebhookUrl: string;
  enabledWebhookEvents: string[];
  playersAuthentication: Partial<{
    appleModel: EPlayersAuthenticationModel;
    appleAppId: string;
    appleOn: boolean;
    appleAppSecret: string;
    googleAppSecret: string;
    googleAppId: string;
    googleModel: EPlayersAuthenticationModel;
    googleOn: boolean;
    fbAppSecret: string;
    fbAppId: string;
    fbModel: string;
    fbOn: boolean;
    userTokenOn: boolean;
    userTokenModel: string;
    usernamePasswordOn: boolean;
    usernamePasswordModel: string;
    otpOn: boolean;
    otpTextModel: string;
  }>;
  deepLinks: any;
  otpGenerateDeeplinkUrl: string;
  integrationProfiles?: Record<string, IntegrationProfile>;
}

export interface PublisherEventType {
  value?: EPublisherEventValue;
  displayName?: string;
  subCategory?: string;
}

export enum ESettingsState {
  COMPANY = 'company',
  USERS = 'users',
  INTEGRATION = 'integration',
  INTEGRATION_PROFILES = 'integrationProfiles',
  PLAYER_AUTH = 'playerAuth',
  PAYMENTS = 'payments',
  ENCRYPTION = 'encryption',
  LAYOUT = 'layout',
  EVENTS = 'events',
  STORE_SETTINGS = 'storeSettings'
}
